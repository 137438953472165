<template>
    <div class="registration">
        <headerLayout class="h" :no_bg="true"/>
        <div class="main">
            <div class="title">
                Agent Registration
            </div>
            <div class="small">
                Enter your name, whatsapp number and working location to start receiving property 
                requests and offers from Bigglandlord
            </div>
            <div class="input">
                <div class="label" v-if="name != ''">Name:</div>
                <input type="text" placeholder="Name" v-model="name">
            </div>
            <div class="input">
                <div class="label" v-if="whatsapp != ''">Whatsapp Number:</div>
                <input type="text" placeholder="Whatsapp Number" v-model="whatsapp">
            </div>
            <div class="input">
                <div class="label" v-if="country != ''">Country:</div>
                <select v-model="country">
                    <option value="" disabled selected>Country</option>
                    <option v-for="c in countries" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="state != ''">State:</div>
                <select v-model="state" :disabled="country == ''">
                    <option value="" disabled selected>State</option>
                    <option v-for="c in s" :value="c.name" :key="c.name">{{c.name}}</option>
                </select>
            </div>
            <div class="input">
                <div class="label" v-if="city != ''">City:</div>
                <select v-model="city" :disabled="state == ''">
                    <option value="" disabled selected>City</option>
                    <option v-for="(city, i) in c" :value="city.name" :key="i">{{city.name}}</option>
                </select>
            </div>

            <loader v-if="loading" :height="'39px'" class="loa"/>
            <div class="btn" v-else @click="submit()">Submit</div>
        </div>
        
        <footerLayout/>
    </div>
</template>

<script>
import headerLayout from '../components/header.vue'

import loader from '../components/loader.vue'

import footerLayout from '../components/footer.vue'

export default {
    components: {
        headerLayout, 
        loader,
        footerLayout
    },
    data() {
        return {
            loading: false,

            name: '',
            whatsapp: '',

            country: '',
            state: '',
            city: '',

            countries: [],
            states: [],
            s: [],
            cities: [],
            c: []
        }
    },
    mounted() {
        this.$http.get('/properties/locations')
        .then(data => {
            let r = data.data
            if (r.success) {
                this.countries = r.countries
                this.states = r.states
                this.cities = r.cities
            }
        })
    },
    watch: {
        country() {
            this.set_states()
        },
        state() {
            this.set_cities()
        }
    },
    methods: {
        submit() {
            if (this.name == '' || this.whatsapp == '' || 
                this.country == '' || this.state == '' || this.city == '') {
                    this.empty();
                }
            if (this.whatsapp.length < 10 || this.whatsapp.length > 11) {
                this.whatsapp_err(); return
            }

            this.loading = true

            let form = new FormData()
            form.append('name', this.name)
            form.append('number', this.whatsapp)
            form.append('country', this.country)
            form.append('state', this.state)
            form.append('city', this.city)

            this.$http.post('/agent/lazy/registration', form)
            .then(data => {
                let r = data.data
                if (r.success) {
                    this.success()
                    this.loading = false

                    setTimeout(() => {
                        this.$router.push('/property_requests')
                    }, 1500);
                }
            })
        },
        set_states() {
            this.s = []
            let x
            for (x of this.states) {
                if (x.country == this.country) {
                    this.s.push(x)
                    this.state = ''
                    this.city = ''
                }
            }
        },
        set_cities() {
            this.city = ''
            this.c = []
            let x
            for (x of this.cities) {
                if (x.state == this.state) {
                    this.c.push(x)
                }
            }
        }
    },
    notifications: {
        empty: {
            type: 'warn',
            title: 'Empty',
            message: 'Make sure all field are filled'
        },
        whatsapp_err: {
            type: 'warn',
            title: 'Whatsapp',
            message: 'Your whatsapp number must be 11 digit'
        },
        success: {
            type: 'success',
            title: 'Success',
            message: 'Your agent registration was successfull'
        }
    }
}
</script>

<style scoped>

    .main {
        max-width: 350px;
        margin: 150px auto;
        margin-top: 230px;
        padding: 30px;
        background-color: #edeef0;
        border-radius: 10px;
    }
    .h {
        position: fixed;
        width: 100%;
        box-sizing: border-box;
        top: 0px;
        left: 0px;
        z-index: 5;
        color: white;
    }
    .title {
        font-weight: 500;
        font-size: 25px;
    }

    
    .small {
        font-size: 12px;
        font-weight: 500;
        opacity: .7;
        margin-bottom: 30px;
        margin-top: 5px;
    }
    .input {
        margin-bottom: 30px;
        position: relative;
    }
    .label {
        position: absolute;
        top: -20px;
        left: 10px;
        font-weight: 500;
        font-size: 14px;
        animation: .2s up;
    }
    @keyframes up {
        from {
            top: 0px;
        }
        to {
            top: -20px;
        }
    }
    input, select {
        width: 100%;
        padding: 12px;
        border-radius: 5px;
        border: none;
        box-sizing: border-box;
        outline: none;
        background-color: white;
    }
    input:-webkit-autofill { 
        -webkit-background-clip: text;
    }
    .err {
        position: absolute;
        right: 0px;
        bottom: -13px;
        font-size: 12px;
        color: red;
        font-weight: 500;
    }
    .loa {
        margin-top: 20px;
    }
    .btn {
        background-color: var(--main);
        color: white;
        cursor: pointer;
        padding: 10px 30px;
        border-radius: 5px;
        font-weight: 500;
        margin-top: 20px;
        text-align: center;
    }
</style>